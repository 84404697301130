// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { getEnv } from './src/lib/env';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = getEnv('ENV');

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://95104f9bc17f43068bcf6f7d2ad47526@o282856.ingest.sentry.io/1510033',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  maxBreadcrumbs: 50,
  environment: ENV,
  attachStacktrace: true,
});
