import getConfig from 'next/config';

export function getEnv(name: string): string | undefined {
  if (process && process.env && process.env[name]) {
    return process.env[name] as string;
  }

  const config = getConfig();
  if (!config || !config.serverRuntimeConfig || !config.publicRuntimeConfig) {
    console.error('can not find ENV: ', name);
    return undefined;
  }
  return config.serverRuntimeConfig[name] || config.publicRuntimeConfig[name];
}

export function getEnvRequired(name: string): string {
  const value = getEnv(name);
  if (!value) {
    throw new Error(`Could not start as env var is missing: ${name}`);
  }

  return value;
}
