import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const theme = {
  fontBlackLight: '#4a4a4a',
  fontGreyDark: '#b9b9b9',
  greyLight: '#e6e6e6',
  black: '#000000',
  fontBlueLight: '#347ddd',
  btBlueLight: '#58b1ff',
  btBlueMidDark: '#2b6fd4',
  bgWhite: '#fff',
  bgGrey: '#f4f4f4',
  bgBorder: 'rgba(0, 0, 0, 0.05)',
  anchorColor: '#e75f70',
  fontGreyLight: '#7f7f7f',
};

type Theme = typeof theme;

export const styled = baseStyled as ThemedStyledInterface<Theme>;
