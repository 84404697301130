import { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import 'antd/dist/antd.css';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme } from 'utils/styled';
import App from 'next/app';
import cookieNode from 'cookie';
import cookieJS from 'js-cookie';
import { NextPageContext } from 'next';
import { getEnv } from 'lib/env';

import {
  defaultTheme,
  ThemeProvider,
  Preflight,
  createGlobalStyle,
} from '@xstyled/emotion';

const getCookie = (ctx: NextPageContext, name: string) => {
  return ctx.req
    ? cookieNode.parse((ctx.req.headers.cookie as string) || '')[name]
    : cookieJS.get(name);
};

const GlobalStyle = createGlobalStyle`
  html, body, #__next {
    height: 100%;
  }
`;

class MainApp extends App<AppProps> {
  static getInitialProps: typeof App.getInitialProps = async context => {
    const GITSTART_HOOKS_URL = getEnv('GITSTART_HOOKS_URL');

    const useSecureCookies = !!GITSTART_HOOKS_URL?.startsWith('https://');
    const { ctx } = context;
    const props = await App.getInitialProps(context);
    const cookiePrefix = useSecureCookies ? '__Secure-' : '';
    const token = getCookie(ctx, `${cookiePrefix}next-auth.session-token`);

    const { pageProps, ...otherProps } = props;
    const { /* query, variables, */ ...leftOverPageProps } = pageProps;

    return {
      ...otherProps,

      pageProps: {
        pageUrl: ctx.req ? ctx.req.url : null,
        token,
        ...leftOverPageProps,
      },
    };
  };

  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <ThemeProvider theme={defaultTheme}>
          <Head>
            <meta name="theme-color" content="#000000" />
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
            />
            <meta name="viewport" content="viewport-fit=cover" />
          </Head>
          <Preflight />
          <GlobalStyle />
          <StyledThemeProvider theme={theme}>
            <Component {...pageProps} />
          </StyledThemeProvider>
        </ThemeProvider>
      </>
    );
  }
}

export default MainApp;
